
import { mapGetters } from "vuex";
import NuxtSSRScreenSize from "nuxt-ssr-screen-size";
import "vue-collapsible-component/lib/vue-collapsible.css";
import Collapsible from "vue-collapsible-component";
import CloseIcon from "@/assets/svg/heroicons/x.svg?inline";
import AngleUp from "@/assets/svg/heroicons/angle-up.svg?inline";
import AngleDown from "@/assets/svg/heroicons/angle-down.svg?inline";
import PhoneIcon from "~/assets/svg/heroicons/phone.svg?inline";
// import Graybg from '~/assets/images/general/Graybg.svg?inline'

export default {
  components: {
    Collapsible,
    CloseIcon,
    AngleUp,
    AngleDown,
    PhoneIcon,
    // Graybg
  },
  mixins: [NuxtSSRScreenSize.NuxtSSRScreenSizeMixin],
  data(d) {
    return {
      browserWidth: 0,
      group_companies: [],
      year: new Date().getFullYear(),
      intercom: typeof d.$intercom !== "undefined" ? d.$intercom : null,
    };
  },
  computed: {
    ...mapGetters({
      products: "getProducts",
      sites: "getSites",
      country: "getCountry",
      locales: "locales/getDefaultLocales",
      currentSiteSettings: "getCurrentSiteSettings",
      currentSite: "getCurrentSite",
      features: "getFeatures",
    }),
    textClass() {
      return this.$store.state.locale === "el"
        ? "text-[17px] 2xl:text-[20px] font-bold"
        : "text-[30px]";
    },
    countryName() {
      const country = this.locales.find((x) => x.slug === this.country);
      return country ? country.country.name : "";
    },
    checkSocialExist() {
      let data = [
        "facebook",
        "instagram",
        "linkedin",
        "twitter",
        "youtube",
        "tiktok",
      ];
      return data.find((_v) => this.currentSiteSettings.socials[_v]);
    },
  },
  mounted() {
    const findData = this.features.find((_v) => _v.slug == "group-companies");
    if (findData && findData.settings) {
      this.group_companies = findData.settings;
    }
    this.browserWidth = this.$vssWidth;
    window.addEventListener("resize", this.windowResized);
  },
  destroyed() {
    window.removeEventListener("resize", this.windowResized);
  },
  methods: {
    windowResized() {
      this.browserWidth = this.$vssWidth;
    },
  },
};
